import React from "react"
import { Breadcrumb, Col, PageHeader, Radio, Row } from "antd"
import { graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import { EventsList } from "../components/SportingEvent/SportingEvent"
import { getEntries, resolveApiResponse } from "../utils/contentful"

const row = {
  align: `middle`,
  gutter: [24, 24],
  justify: `center`,
  type: `flex`,
}

const fullCol = {
  xs: 22,
  sm: 20,
  md: 18,
  lg: 16,
  xl: 14,
}

const heroOverlay = {
  alignItems: `center`,
  background: `rgba(0,0,0,0.2)`,
  color: `white`,
  display: `flex`,
  fontFamily: `arvo`,
  fontSize: 30,
  height: `40vh`,
  justifyContent: `center`,
  left: 0,
  position: `absolute`,
  textAlign: `center`,
  textTransform: `uppercase`,
  top: 0,
  width: `100vw`,
}

const hasMultipleCategories = events => {
  const set = new Set(events.map(e => e.category))
  return set.size > 1
}

export default ({ data: { hero, sport } }) => {
  const [canFilter, setCanFilter] = React.useState(false)
  const [events, setEvents] = React.useState([])
  const [filter, setFilter] = React.useState(`All`)
  const [noEvents, setNoEvents] = React.useState(false)

  React.useEffect(() => {
    const getEvents = async () => {
      const { data, status } = await getEntries({
        content_type: `sportingEvent`,
        order: `fields.eventStart`,
        "fields.eventStart[gte]": new Date().toISOString(),
        "fields.eventType": encodeURIComponent(sport.name),
      })

      if (status === 200) {
        const evts = resolveApiResponse(data)
        if (evts.length === 0) {
          setNoEvents(true)
        } else {
          setEvents(evts)
          setCanFilter(hasMultipleCategories(evts))
        }
      }
    }
    getEvents()
  }, [sport.name])

  const changeFilter = ({ target: { value } }) => {
    setFilter(value)
  }

  const displayedEvents =
    filter === `All` ? events : events.filter(e => e.category === filter)

  return (
    <Shell>
      <Meta
        seo={{
          desc: `Support Tiger ${sport.name} here in Clemson, SC. Attend an event, \
            cheer on the team, then head back to Lakeside Lodge so you can rest and \
            recharge for whatever comes next.`,
          img: `https:${hero.fluid.src}`,
          title: `Clemson ${sport.name} Events and Availability`,
        }}
        uri={`/sports/${sport.slug}/`}
      />
      <Row style={{ marginBottom: 16, width: `100vh` }}>
        <Img
          alt={hero.description}
          fluid={hero.fluid}
          style={{ height: `40vh`, width: `100vw` }}
        />
        <div style={heroOverlay}>
          <span style={{ borderBottom: `4px solid orange` }}>
            Clemson {sport.name}
          </span>
        </div>
      </Row>
      <Row {...row}>
        <Col {...fullCol}>
          <PageHeader
            onBack={() => navigate(`/sports/`)}
            style={{ marginTop: 0, padding: 0 }}
            title={
              <Breadcrumb style={{ padding: 4 }}>
                <Breadcrumb.Item>
                  <Link to="/sports/">Sports</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{sport.name}</Breadcrumb.Item>
              </Breadcrumb>
            }
          />
        </Col>
        <Col {...fullCol}>
          <SeoHeader
            human={{ content: `${sport.name} Schedule`, level: 2 }}
            seo={{
              content: `Clemson ${sport.name} Events and Availability`,
              level: 1,
            }}
            marginBottom={32}
            marginTop={0}
          />
          {canFilter && (
            <span>
              Filter:
              <Radio.Group
                buttonStyle="solid"
                defaultValue="All"
                onChange={changeFilter}
                size="large"
                style={{ marginLeft: 12 }}
              >
                <Radio.Button value="All">All</Radio.Button>
                <Radio.Button value="Men's">Men's</Radio.Button>
                <Radio.Button value="Women's">Women's</Radio.Button>
              </Radio.Group>
            </span>
          )}
          <EventsList events={displayedEvents} noEvents={noEvents} />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query ($hero: String!, $name: String!) {
    hero: contentfulAsset(title: { eq: $hero }) {
      description
      fluid(maxWidth: 1360, quality: 75) {
        ...GatsbyContentfulFluid_tracedSVG
      }
    }
    sport: sportsJson(name: { eq: $name }) {
      name
      slug
    }
  }
`
